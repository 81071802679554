export default {
  props: {
    block: {
      type: Object,
      default: { id: null, type: "paragraph", data: { text: "" } },
    },
  },
  data: function () {
    return {};
  },
  computed: {
    blockId() {
      return (
        (this.block.id ? this.block.id : "") +
        "-block-" +
        utilities.getUniqueNumber()
      );
    },
  },
  methods: {
    getElementUUID(seed) {
      return seed + "-" + utilities.getUniqueNumber();
    },
  },
};
