<template>
  <ul v-if="listType === 'unordered'" v-bind="$attrs">
    <li v-for="item of list"
        :key="getElementUUID('list-item')"
        v-html="item"></li>
  </ul>

  <ol v-if="listType === 'ordered'" v-bind="$attrs">
    <li v-for="item of list"
        :key="getElementUUID('list-item')"
        v-html="item"></li>
  </ol>
</template>

<script>
import contentBlock from "@/client/extensions/mixins/baseComponents/html/contentBlock";

export default {
  mixins: [contentBlock],
  props: {},
  data: function () {
    return {};
  },
  computed: {
    blockHtml() {
      return this?.block?.data?.text ? this.block.data.text : "";
    },
    list() {
      return this?.block?.data?.items ? this?.block?.data?.items : [];
    },
    listType() {
      return this?.block?.data?.style === "ordered" ? "ordered" : "unordered";
    },
  },

};
</script>

<style scoped lang="scss"></style>
